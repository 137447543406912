import { Injectable } from '@angular/core';
import { Common } from '@environment';
import { ApiService } from '@gal/core';
import { Observable } from 'rxjs';
import { HolidayListParams } from '../models/holiday-list-params.model';
import { HolidayList } from '../models/holiday-list.model';
import { HolidayRequest } from '../models/holiday-request.model';
import { Holiday } from '../models/holiday.model';

export interface WorkdayDate {
  date: string;
}

@Injectable({
  providedIn: 'root'
})
export class HolidayService {
  private apiUrl = Common.generalParameterApiUrl;

  constructor(private api: ApiService) {}

  deleteHoliday(id: string): Observable<null> {
    return this.api.delete<null>(`${this.apiUrl}/holidays/${id}`, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  postHoliday(body: HolidayRequest): Observable<Holiday> {
    return this.api.post(`${this.apiUrl}/holidays`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  getHolidayList(params: HolidayListParams): Observable<HolidayList> {
    return this.api.get<HolidayList>(`${this.apiUrl}/holidays`, params, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  putHoliday(body: HolidayRequest): Observable<Holiday> {
    return this.api.put(`${this.apiUrl}/holidays/${body.id}`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  getNextWorkdayDate(date: string): Observable<WorkdayDate> {
    return this.api.get(`${this.apiUrl}/holidays/next-work-day`, { date }, {
      defaultErrorHandling: false,
      showLoading: false
    });
  }

  getIfIsHoliday(date: string): Observable<any> {
    return this.api.get(`${this.apiUrl}/holidays/is-holiday`, { date }, {
      defaultErrorHandling: false,
      showLoading: false
    });
  }
}
