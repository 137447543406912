import { Injectable } from '@angular/core';
import { Common } from '@environment';
import { ApiService, AuthService } from '@gal/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BillingDate,
  BillingDateConfigurations,
  BillingDateParameters,
  GeneralConfigurations,
  GeneralParameters
} from '../models/general-parameters.model';
import { BillingTimeConfigurations, BillingTimeParameters } from './../models/general-parameters.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralParametersService {
  private apiUrl = Common.generalParameterApiUrl;
  private apiFullUrl = `${this.apiUrl}/configuration`;

  constructor(private api: ApiService, private auth: AuthService) {}

  putGeneralParameters(body: GeneralParameters): Observable<GeneralParameters> {
    return this.api.put(this.apiFullUrl, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  getGeneralParameters(silent: boolean = false): Observable<GeneralParameters> {
    return this.api.get<GeneralParameters>(`${this.apiFullUrl}/name/GENERAL_CONFIGURATION`, null, {
      defaultErrorHandling: true,
      showLoading: !silent
    });
  }

  getGeneralParametersValue(silent: boolean = false): Observable<GeneralConfigurations> {
    return this.getGeneralParameters(silent).pipe(map(item => item.value));
  }

  getBillingTimeConfigurationsValue(): Observable<BillingTimeConfigurations> {
    return this.api
      .get<BillingTimeParameters>(`${this.apiFullUrl}/billing-time/name/BILLING_TIME_HOURS`, null, {
        defaultErrorHandling: false,
        showLoading: false
      })
      .pipe(map(item => item.value));
  }

  getBillingDateConfigurationsValue(): Observable<BillingDate> {
    return this.api
      .get<BillingDate>(`${this.apiFullUrl}/billeting-date/name/BILLING_DATE`, null, {
        defaultErrorHandling: false,
        showLoading: false
      });
  }

  putBillingDateConfigurations(id: string, value: BillingDateConfigurations): Observable<BillingDateConfigurations> {
    const billingDate = {
      id,
      name: 'BILLING_DATE',
      value: {
        start_date_billing: value.start_date_billing,
        billing_day: value.billing_day,
        end_billing: value.end_billing
      }
    };
    return this.api.put<BillingDateConfigurations>(
      `${this.apiFullUrl}/billeting-date`,
      billingDate,
      {
        defaultErrorHandling: true,
        showLoading: true
      }
    );
  }

  postBillingDateConfigurations(value: BillingDateConfigurations): Observable<BillingDateConfigurations> {
    const billingDate = {
      name: 'BILLING_DATE',
      value: {
        start_date_billing: value.start_date_billing,
        billing_day: value.billing_day,
        end_billing: value.end_billing
      }
    };
    return this.api.post<BillingDateConfigurations>(
      `${this.apiFullUrl}/billeting-date`,
      billingDate,
      {
        defaultErrorHandling: true,
        showLoading: true
      }
    );
  }
}
